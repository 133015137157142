import React from "react"
import "./App.css"
import "mapbox-gl/dist/mapbox-gl.css"

// import Map from "./map/Map"
import Heatmap from "./map/heatmap/heatmap"

function App() {
    return (
        <div className="App">
            {/*<Map />*/}
            <Heatmap />
        </div>
    )
}

export default App
