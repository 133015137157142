import React, { Component } from "react"
// import { render } from "react-dom"
import MapGL, { Source, Layer } from "react-map-gl"
import ControlPanel from "./control-panel"
import { json as requestJson } from "d3-request"
import { heatmapLayer } from "./map-style"

import { envConfig } from "../../util/config"

// function filterFeaturesByDay(featureCollection, time) {
//     const date = new Date(time)
//     const year = date.getFullYear()
//     const month = date.getMonth()
//     const day = date.getDate()
//     const features = featureCollection.features.filter((feature) => {
//         const featureDate = new Date(feature.properties.time)
//         return (
//             featureDate.getFullYear() === year &&
//             featureDate.getMonth() === month &&
//             featureDate.getDate() === day
//         )
//     })
//     return { type: "FeatureCollection", features }
// }

export default class Heatmap extends Component {
    constructor(props) {
        super(props)
        const current = new Date().getTime()

        this.state = {
            viewport: {
                latitude: 40,
                longitude: -100,
                zoom: 3,
                bearing: 0,
                pitch: 0
            },
            allDay: true,
            startTime: current,
            endTime: current,
            selectedTime: current,
            earthquakes: null
        }

        // this._handleChangeDay = this._handleChangeDay.bind(this)
        // this._handleChangeAllDay = this._handleChangeAllDay.bind(this)
    }

    componentDidMount() {
        requestJson(
            // "https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson",
            envConfig["dataSource"]["domain"] + "/venues.geojson",
            (error, response) => {
                if (!error) {
                    // Note: In a real application you would do a validation of JSON data before doing anything with it,
                    // but for demonstration purposes we ingore this part here and just trying to select needed data...
                    // const features = response.features
                    // const endTime = features[0].properties.time
                    // const startTime = features[features.length - 1].properties.time

                    this.setState({
                        data: response,
                        earthquakes: response
                        // endTime,
                        // startTime,
                        // selectedTime: endTime
                    })
                }
            }
        )
    }

    _onViewportChange = (viewport) => this.setState({ viewport })

    _onSettingsChange = (name, value) => {
        this.setState({
            settings: { ...this.state.settings, [name]: value }
        })
    }

    // _handleChangeDay = (time) => {
    // this.setState({ selectedTime: time })
    // if (this.state.earthquakes) {
    //     this.setState({
    //         data: filterFeaturesByDay(this.state.earthquakes, time)
    //     })
    // }
    // }

    // _handleChangeAllDay = (allDay) => {
    // this.setState({ allDay })
    // if (this.state.earthquakes) {
    //     this.setState({
    //         data: allDay
    //             ? this.state.earthquakes
    //             : filterFeaturesByDay(
    //                   this.state.earthquakes,
    //                   this.state.selectedTime
    //               )
    //     })
    // }
    // }

    // eslint-disable-next-line no-unused-vars
    render() {
        const {
            viewport,
            data
            // allDay,
            // selectedTime,
            // startTime,
            // endTime
        } = this.state

        return (
            <div style={{ height: "100vh" }}>
                <MapGL
                    {...viewport}
                    width="100%"
                    height="100%"
                    mapStyle="mapbox://styles/mapbox/dark-v9"
                    onViewportChange={this._onViewportChange}
                    mapboxApiAccessToken={envConfig["mapbox"]["token"]}
                >
                    {data && (
                        <Source type="geojson" data={data}>
                            <Layer {...heatmapLayer} />
                        </Source>
                    )}
                </MapGL>
                <ControlPanel
                    containerComponent={this.props.containerComponent}
                    // startTime={startTime}
                    // endTime={endTime}
                    // selectedTime={selectedTime}
                    // allDay={allDay}
                    // onChangeDay={this._handleChangeDay}
                    // onChangeAllDay={this._handleChangeAllDay}
                />
            </div>
        )
    }
}
