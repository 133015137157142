import React from "react"
import * as Sentry from "@sentry/browser"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { envConfig } from "./util/config"

const environment = envConfig["environment"]
const sentryConfig = envConfig["sentry"]

if (sentryConfig["enabled"]) {
    // console.log('Sentry Enabled');
    Sentry.init({
        dsn: sentryConfig["dsn"],
        environment: environment
    })
}

ReactDOM.render(<App />, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
